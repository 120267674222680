import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  InputGroup,
  Row,
  Form,
  Modal,
  Image,
} from "react-bootstrap";
import { base_url, postHeader } from "../../../../Helpers/request";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../../../../Components/Loader";
import { useSelector } from "react-redux";
import { media_details } from '../../../../Helpers/request';
import newsletterimg from '../../../../Assets/images/newsletter.jpg'
import Popupimg from '../Assets/image/Popup.webp'
function SignupTo() {
  const history = useHistory();
  const currencycode = useSelector(
    (state) => state.persistedReducer.home.currencycode
  );
  const [newsletteremail, setNewsletteremail] = useState("");
  const [loader, setLoader] = useState(false);
  const newsletter = (e) => {
    e.preventDefault();
    setLoader(true);
    const data = {
      currency_code: currencycode,
      email: newsletteremail,
    };
    axios
      .post(base_url + "/common/subscribe_newsletter", data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          setLoader(false);
          setNewsletteremail("");
          return swal(res.data.message, "", "success");
        } else {
          setLoader(false);
          return swal(res.data.message, "", "error");
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  return (
    <section className="venice_signup_to_section">
      <Container className="p-0">
        {/* <Row className="align-items-center">

          <Col md={7} lg={7} xs={12} className="content-box">
            <h2>Join Our Mailing List</h2>
            <p>Become a preferred subscriber and get a special offer on your purchase.</p>
            <div className="venice_signup_to_input_field">
              <Form className="gs_news_form w-100" onSubmit={newsletter}>
                <InputGroup>
                  <FormControl
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    type="email"
                    value={newsletteremail}
                    placeholder="Your email address"
                    required
                    onChange={(e) => setNewsletteremail(e.target.value)}
                  />
                  <Button
                    className="btn outline-base-btn large"
                    type="submit"
                    id="button-addon2"
                  >
                    Subscribe
                  </Button>
                </InputGroup>
              </Form>
            </div>
            <p>SIGN UP FOR EXCLUSIVE UPDATES, NEW ARRIVALS AND INSIDER-ONLY DISCOUNTS</p>
            <ul className="social_accounts">
              {media_details.map((media, id) =>
                <li key={id}>
                  <div className="venice_footer_content_one_social_icn_div">
                    <a href={media.account_link} target="_blank">{media.account_icon}</a>
                  </div>
                </li>
              )}
            </ul>
          </Col>
          <Col lg={5} md={5} xs={12} className="img-box ml-auto pr-0 p-0">
            <Image className="img-fluid" src={newsletterimg} alt="" />
          </Col>
        </Row> */}
        <img src={Popupimg} alt="Popup" className="img-fluid" />
      </Container>
           
      <Modal show={loader}>
        <Loader />
      </Modal>
    </section>
  );
}

export default SignupTo;
