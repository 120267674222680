import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DiamondRingImg from '../../../../Assets/images/venice/setting.png'
const DiamondRing = () => {
    return (
        <section className='vanice-DiamondRing'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='WordStyle'>D</div>
                        <div className='textBox'>
                            <h5 className='vanice-DiamondRing-subtitle'>CREATE YOUR OWN</h5>
                            <h2 className='vanice-DiamondRing-title'>Diamond Rings</h2>
                            <p className='vanice-DiamondRing-text'>Select a setting and choose a diamond to create your own diamond engagement ring.</p>
                            <p className='vanice-DiamondRing-text'><b>Need help?</b> We're always here.</p>
                            <div className='vanice-DiamondRing-btn'>
                                <Link to='/ringsettings'>Start with a setting</Link>
                                <Link to='/diamonds'>Start with a diamond</Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <Image className='img-fluid' alt='' src={DiamondRingImg} />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DiamondRing