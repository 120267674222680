import React, { useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import diamond1 from '../../../../Assets/images/venice/diamond_screen.jpg'
import diamond2 from '../../../../Assets/images/venice/diamond.png'
import diamondvideo from '../../../../Assets/images/venice/diamond.mp4'
import '../../../../../node_modules/react-modal-video/css/modal-video.css';
import ModalVideo from 'react-modal-video';
const DiamondCollection = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className='DiamondCollection'>
      <Container fluid>
        <Row>
          <Col md={6}>
            <div className='videoBox'>
              <Image className='img-fluid' src={diamond1} alt='' />
              {/* <div class="qode_video_box_button_holder" onClick={() => setOpen(true)}>
                <span class="qode_video_box_button">
                  <span class="qode_video_box_button_arrow">
                  </span>
                </span>
              </div>
              <ModalVideo
                channel="custom"
                isOpen={isOpen}
                url={diamondvideo}
                onClose={() => setOpen(false)}
              /> */}
            </div>
          </Col>
          <Col md={6}>
            <div className='contentBox'>
              <Image className='img-fluid' src={diamond2} alt='' />
              <h2>DIAMOND<br /> COLLECTION</h2>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default DiamondCollection