import React, { useEffect, useState } from "react";
import { media_details } from "../../../../Helpers/request";
import Modal from "react-bootstrap/Modal";
import SignupTo from "../Home/SignupTo";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { isMobile } from "react-device-detect";
import { RxCross2 } from "react-icons/rx";
const Social_icons = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    // Check if the modal has been shown before
    const hasModalBeenShown = localStorage.getItem("hasModalBeenShown");

    if (!hasModalBeenShown) {
      handleShow(true);
      localStorage.setItem("hasModalBeenShown", "true"); // Mark the modal as shown
    }
  }, []);
  return (
    <>
      <ul className="fixed_social_icons">
        {media_details.map((media, id) => (
          <li key={id}>
            <div className="venice_footer_content_one_social_icn_div">
              <a href={media.account_link} target="_blank">
                {media.account_icon}
              </a>
            </div>
          </li>
        ))}
        {isMobile ? (
          <li>
            <div className="venice_footer_content_one_social_icn_div">
              <a onClick={handleShow}>
                <HiOutlineNewspaper />
              </a>
            </div>
          </li>
        ) : ( ''
        //   <li className="Newsletter" onClick={handleShow}>
        //     Newsletter
        //   </li>
        )}
      </ul>

      <Modal size="lg" show={show} onHide={handleClose} centered className="popupImageLoad">
        <Modal.Body className="p-0">
          <RxCross2 className="closeNewsletter" onClick={handleClose} />
          <SignupTo />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Social_icons;
